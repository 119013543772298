.add-attraction {
 position: relative;
 /*padding: 10px;*/
 background-color: RGBA(42,167,158,0.8);
}

.add-attraction h2 {
 color: white;
 padding-top: 40px;
 text-align: center;
}

.add-attraction img {
 position:absolute;
 z-index: -1;
 height: 100%;
 width: 100%;
}

.add-attraction_container {
 margin: 0 auto;
 max-width: 1280px;
 /*background-color: red;*/
 padding: 30px 0px 80px;
 display: flex;
 flex-direction: row;
 justify-content: space-between;
}

.add-attraction_box {
 display: flex;
 flex-direction: column;
 justify-content: space-evenly;
 flex-basis: 45%;
 background-color: white;
 padding: 30px 30px;
 border-radius: 10px;
}

.add-attraction_box ol {
 padding-left: 20px;
}

.fa-circle-info {
color: dodgerblue;
 font-size: 18px;
}

.add-attraction_box li {
 padding-top: 8px;
 font-size: 18px;
}

.add-attraction_box p {
 padding-top: 14px;
 font-size: 16px;
 font-weight: 550;
}

.add-form {
 display: flex;
 flex-direction: column;

 /*margin: 0 50px*/
}

.add-form input {
 background-color: #ddd;
 padding: 8px;
 margin-top: 10px;
 border: none;
 border-radius: 8px;
}

.add-form-textarea {
 background-color: #ddd;
 padding: 10px;
 border-radius: 8px;
 margin-top: 10px;
 border: none;
 height: 200px;
}

