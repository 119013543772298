.footer {
    margin-top: 10px;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    max-width: 1280px;
    margin: 0 auto;
    padding: 30px 10px 30px;
    background-color: white;
}

.footer-item {
    flex-basis: 30%;
    font-size: 16px;
    font-weight: 550;
}

.footer-item_img-partner {
    width: 50px;
}

.footer-copy {
    background-color: #2AA79E;
    text-align: center;
    padding: 10px;
    font-size: 14px;
    font-weight: 550;
    color: white;
}