* {
  box-sizing: border-box;
  font-family: sans-serif;

margin: 0px;
  padding: 0px;

}

p {
  font-family: sans-serif;
  padding: 0px;
  word-break: break-word;
  /*overflow: hidden;*/

  /*text-overflow: ellipsis;*/

  /*white-space: nowrap;*/

}

body {
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fa-solid .fa-heart {
  color: red;
}


